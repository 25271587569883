import React from 'react'
import NavPcAndHoriz from './NavPcAndHoriz'


function Main() {
  return (
    <div>
    <NavPcAndHoriz />
        <div className='navRow1'>
        </div>
        <div className='mainTable'>
         </div>
    </div>
  )
}

export default Main