import React from 'react'
import '../index.css'
import {Link} from 'react-router-dom'

function BottomNavMob() {
    return (
        <div className="bottomNavMob">
        <Link to="/"><div className="cardIcons2"><i class="fa-duotone fa-house-chimney-blank"></i></div></Link>
        <Link to="/speisekarte/angebote"><div className="cardIcons2"><i class="fa-duotone fa-burger-fries"></i></div></Link>
        <a href="tel:05281621344"><div className="cardIcons2"><i class="fa-duotone fa-phone-arrow-up-right"></i></div></a>
        <a href="https://www.google.com/maps/place/Kervan+Grill/@51.9851984,9.2595549,15z/data=!4m5!3m4!1s0x0:0xfa98ac536616f5f8!8m2!3d51.9851984!4d9.2595549"><div className="cardIcons2"><i class="fa-duotone fa-map-location-dot"></i></div></a>
        
        </div>
    )
}

export default BottomNavMob
