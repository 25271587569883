import React from 'react'
import {Routes, Route,} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'
import Div100vh from 'react-div-100vh'
import "./css/all.css"
import BottomNavMob from './components/BottomNavMob'
import Main from './components/kervan/Main'
import Loader from './js/Loader'
const LazyHome = React.lazy(() => import('./Pages/Home'))
const LazySpeisekarte = React.lazy(() => import('./Pages/Speisekarte'))
const LazyAngebote = React.lazy(() => import('./Pages/SpCategory/Angebote'))
const LazyTurk = React.lazy(() => import('./Pages/SpCategory/Turk'))
const LazyDrehspiessImFladenbrot = React.lazy(() => import('./Pages/SpCategory/Turk/DrehspiessImFladenbrot'))
const LazyDrehspiessteller = React.lazy(() => import('./Pages/SpCategory/Turk/Drehspiessteller'))
const LazyDrehspiessrolle = React.lazy(() => import('./Pages/SpCategory/Turk/Drehspiessrolle'))
const LazyLahmacun = React.lazy(() => import('./Pages/SpCategory/Turk/Lahmacun'))
const LazyImbiss = React.lazy(() => import('./Pages/SpCategory/Imbiss'))
const LazySchnitzel = React.lazy(() => import('./Pages/SpCategory/Imbiss/Schnitzel'))
const LazyHamburger = React.lazy(() => import('./Pages/SpCategory/Imbiss/Hamburger'))
const LazyChickenNuggets = React.lazy(() => import('./Pages/SpCategory/Imbiss/ChickenNuggets'))
const LazyCurrywurst = React.lazy(() => import('./Pages/SpCategory/Imbiss/Currywurst'))
const LazyChickenWings = React.lazy(() => import('./Pages/SpCategory/Imbiss/ChickenWings'))
const LazyFalafel = React.lazy(() => import('./Pages/SpCategory/Imbiss/Falafel'))
const LazyTexasChiliBurgerXXL = React.lazy(() => import('./Pages/SpCategory/Imbiss/TexasChiliBurgerXXL'))
const LazyPasta = React.lazy(() => import('./Pages/SpCategory/Pasta'))
const LazySpaghetti = React.lazy(() => import('./Pages/SpCategory/Pasta/Spaghetti'))
const LazyMaccheroni = React.lazy(() => import('./Pages/SpCategory/Pasta/Maccheroni'))
const LazyTortellini = React.lazy(() => import('./Pages/SpCategory/Pasta/Tortellini'))
const LazySalate = React.lazy(() => import('./Pages/SpCategory/Salate'))
const LazyPizza = React.lazy(() => import('./Pages/SpCategory/Pizza'))
const LazyVegetarischePizza = React.lazy(() => import('./Pages/SpCategory/Pizza/VegetarischePizza'))
const LazyCalzone = React.lazy(() => import('./Pages/SpCategory/Pizza/Calzone'))
const LazyFischPizza = React.lazy(() => import('./Pages/SpCategory/Pizza/FischPizza'))
const LazyDrehspiessPizza = React.lazy(() => import('./Pages/SpCategory/Pizza/DrehspiessPizza'))
const LazyPizzaC = React.lazy(() => import('./Pages/SpCategory/Pizza/PizzaC'))
const LazyFamilienpizza = React.lazy(() => import('./Pages/SpCategory/Pizza/Familienpizza'))
const LazyDrinks = React.lazy(() => import('./Pages/SpCategory/Drinks'))
const LazyUberUns = React.lazy(() => import('./Pages/UberUns'))
const LazyLieferung = React.lazy(() => import('./Pages/Lieferung'))
const LazyA404 = React.lazy(() => import('./Pages/A404'))

function App() {
  return (
    <Div100vh>
    <Loader/>
    <div class="background">
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
</div>
<Main />
  <div className="navColumn">
    <div className="navRow">
    </div>
  </div>
  <div className='alert'></div>
     <BottomNavMob />
     <AnimatePresence>
     <React.Suspense fallback={<h1>Loading...</h1>}>
      <Routes>
       <Route path="/" element={<LazyHome />} />
       <Route path="Speisekarte" element={ <LazySpeisekarte />} >
          <Route path="Angebote" element={<LazyAngebote />} />
          <Route path="Turk" element={<LazyTurk />}>
            <Route path="DrehspiessImFladenbrot" element={<LazyDrehspiessImFladenbrot />}/> 
            <Route path="Drehspiessteller" element={<LazyDrehspiessteller />}/> 
            <Route path="Drehspiessrolle" element={<LazyDrehspiessrolle />}/> 
            <Route path="Lahmacun" element={<LazyLahmacun />}/>
          </Route>
          <Route path="Imbiss" element={<LazyImbiss />}>
          <Route path="Schnitzel" element={<LazySchnitzel />}/>
          <Route path="Hamburger" element={<LazyHamburger />}/>
          <Route path="ChickenNuggets" element={<LazyChickenNuggets />}/>
          <Route path="Currywurst" element={<LazyCurrywurst />}/>
          <Route path="ChickenWings" element={<LazyChickenWings />}/>
          <Route path="Falafel" element={<LazyFalafel />}/>
          <Route path="TexasChiliBurgerXXL" element={<LazyTexasChiliBurgerXXL />}/>
          </Route>
          <Route path="Pizza" element={<LazyPizza />}>
          <Route path="VegetarischePizza" element={<LazyVegetarischePizza />}/>
          <Route path="Calzone" element={<LazyCalzone />}/>
          <Route path="FischPizza" element={<LazyFischPizza />}/>
          <Route path="DrehspiessPizza" element={<LazyDrehspiessPizza />}/>
          <Route path="PizzaC" element={<LazyPizzaC />}/>
          <Route path="Familienpizza" element={<LazyFamilienpizza />}/>
          </Route>
          <Route path="Pasta" element={<LazyPasta />}>
          <Route path="Spaghetti" element={<LazySpaghetti />}/>
          <Route path="Maccheroni" element={<LazyMaccheroni />}/>
          <Route path="Tortellini" element={<LazyTortellini />}/>
          </Route>
          <Route path="Salate" element={<LazySalate />} />
          <Route path="Drinks" element={<LazyDrinks />} />
       </Route>
       <Route path="UberUns" element={ <LazyUberUns />} />
       <Route path="Lieferung" element={<LazyLieferung />} />
       <Route path="*" element={<LazyA404 />} />
     </Routes>
     </React.Suspense>
     </AnimatePresence>
  </Div100vh>
  );
}

export default App;
