import Lottie from 'lottie-react-web'
import loaderLogo from './loaderLogo.json'


const App = () => (
    <Lottie
      options={{
        animationData: loaderLogo,
      }}
    />
  )
   
  export default App