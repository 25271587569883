import React from 'react'
import {Link} from 'react-router-dom'

function NavPcAndHoriz() {
  return (
    <div>
        <div className='navButtonPlaceHolder'>
            <div className='placeHolderTop'>
            <Link to="/"><div className='navButton'><div className='navIcons'><i class="fa-duotone fa-house-chimney-blank"></i></div></div></Link>
                
            </div>
            <div className='placeHolderMid'>
            <Link to="/speisekarte/angebote">
            <div className='navButton navButtonMid'>
                    <div className='navIcons navIcons2'>
                    <i class="fa-solid fa-burger-fries"></i>
                    </div>
                </div>
                </Link>
                <Link to="/uberUns">
                <div className='navButton navButtonMid'>
                    <div className='navIcons navIcons2'>
                    <i class="fa-solid fa-people-group"></i>
                    </div>
                </div>
                </Link>
                <Link to="/Lieferung">
                <div className='navButton navButtonMid'>
                <div className='navIcons navIcons2'>
                    <i class="fa-solid fa-person-carry-box"></i>
                    </div>
                </div>
                </Link>
            </div>
            <div className='placeHolderBottom'>
            <div className='navIcons3 '>
            <i class="fa-brands fa-facebook"></i>
                    </div>
            </div>
        </div>
        
    </div>
  )
}

export default NavPcAndHoriz