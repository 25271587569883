import { useState, useEffect } from 'react'
import LoaderBody from './LoaderBody';

const HideIn3Seconds = () => {
  const [hideComponent, sethideComponent] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      sethideComponent(false);
    }, 2000);
}, []);

  return <>{
    hideComponent && <div className='Loader'>
    <div class="background">
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
 <span></span>
</div>
      <div className='LoaderLogo'> <LoaderBody /></div>
   </div> 
   } 
   </>;
};
export default HideIn3Seconds;